import React, { useState, useEffect } from "react"
import FamewallEmbed from 'react-famewall'

import Image from "../image"

import { SearchIcon, SparklesIcon, UserGroupIcon, ChatAlt2Icon, BellIcon } from '@heroicons/react/outline'


const ReviewSections = () => {

  // state
  const sections = [
    {'key': 'useful', 'famewall': 'gummysearch-uses', 'title': 'Useful'},
    {'key': 'valueable', 'famewall': 'gummysearch-value', 'title': 'Valuable'},
    {'key': 'delightful', 'famewall': 'gummysearch-fun', 'title': 'Delightful'},
  ];
  const [section, setSection] = useState(sections[0]);


  // actions
  const changeSection = (s) => {
    setSection(null);
    setTimeout(() => {
      setSection(s);
    }, 100)
  }

  return (
    <div id="reviews" className="relative bg-gray-900 text-white">
      <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:max-w-7xl">
        <h2 className="text-base text-center sm:text-lg text-semibold opacity-75">People ❤️ GummySearch because it is...</h2>

        <div className="flex mt-2">
          <div className="mx-auto flex items-center">
            {sections.map(s => (
              <div key={s.key} className={`px-4 py-2 mx-1 font-medium text-md rounded-md ${s.key === (section && section.key)  ? 'bg-gray-600 text-white' : 'bg-gray-800 cursor-pointer hover:bg-gray-700'}`}
                onClick={() => changeSection(s)}
              >
                {s.title}
              </div>
            ))}
          </div>
        </div>

        <div style={{minHeight: '600px'}}>
          {section ? (
            <div>
              {section.key === 'useful' ? (
                <div id={section.famewall}><FamewallEmbed wallUrl={section.famewall} /></div>
              ) : section.key === 'valueable' ? (
                <div id={section.famewall}><FamewallEmbed wallUrl={section.famewall} /></div>
              ) : section.key === 'delightful' ? (
                <div id={section.famewall}><FamewallEmbed wallUrl={section.famewall} /></div>
              ) : ''}
            </div>
          ) : ''}
        </div>

        <div className="flex my-10">
          <div className="mx-auto flex items-center">
            {sections.map(s => (
              <div key={s.key} className={`px-4 py-2 mx-1 font-medium text-md rounded-md ${s.key === (section && section.key)  ? 'bg-gray-600 text-white' : 'bg-gray-800 cursor-pointer hover:bg-gray-700'}`}
                onClick={() => {
                  changeSection(s);
                  if (typeof(window.document) !== "undefined"){
                    window.document.getElementById(`reviews`).scrollIntoView({
                        behavior: 'smooth'
                    });
                  }
                }}
              >
                {s.title}
              </div>
            ))}
          </div>
        </div>

      </div>
    </div>
  );
}

export default ReviewSections;
