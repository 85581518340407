import React from "react"
// import FamewallEmbed from 'react-famewall'

import Layout from "../components/layout"
import SEO from "../components/seo"

import ReviewSections from "../components/reviews/sections";
import CTA from "../components/pricing/cta";
import Header from '../components/blog/header';


const ReviewsPage = ({path}) => (
  <Layout
    title="Reviews" subtitle={"Thoughts from GummySearch customers"}
    includeBanner={false} lightHeader={false}
    seoComponent={<SEO title="Reviews" description="Reviews & Ratings from GummySearch customers" path={path} />}
  >
    <div className="content-body markdown-body">
      <Header title="GummySearch Reviews" />
    </div>
    <ReviewSections />
    <CTA />
  </Layout>
)


export default ReviewsPage;
